<template>
  <!-- Page -->
   <div class="login-box" style="width: 400px">
      <div class="card card-outline card-primary">

         <div class="card-header text-center">
            <img
               style="width: 300px; height: 60px; border-radius: 0%;"
               class="img-size-50 img-circle mb-3 mr-2"  src="/static/dist/img/logo.png" alt="logo image">
         </div>

         <!-- Page content -->
         <router-view/>
         <!-- /.page content -->
      </div>
   </div>
</template>

<script>
export default {
   name: "Auth",
   created(){
      document.querySelector('body').className = "hold-transition login-page"
   }
}
</script>

<style>
.login-box{}
</style>
